import refs from '../refs/refs';
const { howItWorksListContainerDesktop, howItWorksListWidthBoxMobile } = refs;

const removeClassHowItWorksList = () => {
  if (window.innerWidth >= 992) {
    howItWorksListContainerDesktop.classList.add('container');
    howItWorksListWidthBoxMobile.forEach(item => {
      item.classList.remove('container');
    });
  } else {
    howItWorksListContainerDesktop.classList.remove('container');
    howItWorksListWidthBoxMobile.forEach(item => {
      item.classList.add('container');
    });
  }
};

window.addEventListener('DOMContentLoaded', removeClassHowItWorksList);
window.addEventListener('resize', removeClassHowItWorksList);
