import refs from '../refs/refs';
import { scrollToTarget } from '../common/scroll-to-target';
const { heroCircleBtns, heroDownBtns, sectionHowItWorks, sectionBenefits } =
  refs;

heroCircleBtns.forEach(btn => {
  btn.addEventListener('click', () => {
    scrollToTarget(sectionHowItWorks);
  });
});

heroDownBtns.forEach(btn => {
  btn.addEventListener('click', () => {
    scrollToTarget(sectionBenefits);
  });
});
