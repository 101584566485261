import refs from '../refs/refs';
import Swiper from 'swiper/swiper-bundle.min.mjs';
import throttle from 'lodash.throttle';
const { partnersGalleryBlockFirst, partnersGalleryBlockSecond } = refs;

let transitionValue = 0.5;
let transitionValueWhenScroll = 50;

let initValueFirstBlock = 0;
let isScrollToBottomFirstBlock = false;

let initValueSecondBlock = 0;
let isScrollToBottomSecondBlock = true;

let lastScrollTop = 0;

const handleInitGalleryFirstBlock = () => {
  const animateFirstBlock = () => {
    if (isScrollToBottomFirstBlock) {
      initValueFirstBlock += transitionValue;
    } else {
      initValueFirstBlock -= transitionValue;
    }

    partnersGalleryBlockFirst.style.backgroundPosition = `${initValueFirstBlock}px center`;

    window.requestAnimationFrame(animateFirstBlock);
  };

  animateFirstBlock();
};

const handleInitGallerySecondBlock = () => {
  const animateSecondBlock = () => {
    if (isScrollToBottomSecondBlock) {
      initValueSecondBlock += transitionValue;
    } else {
      initValueSecondBlock -= transitionValue;
    }

    partnersGalleryBlockSecond.style.backgroundPosition = `${initValueSecondBlock}px center`;

    window.requestAnimationFrame(animateSecondBlock);
  };

  animateSecondBlock();
};

const handleChangeDirectionWhenScroll = () => {
  let currentScrollTop =
    window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

  if (currentScrollTop > lastScrollTop) {
    initValueFirstBlock -= transitionValueWhenScroll;
    isScrollToBottomFirstBlock = false;

    initValueSecondBlock += transitionValueWhenScroll;
    isScrollToBottomSecondBlock = true;
  } else {
    initValueFirstBlock += transitionValueWhenScroll;
    isScrollToBottomFirstBlock = true;

    initValueSecondBlock -= transitionValueWhenScroll;
    isScrollToBottomSecondBlock = false;
  }

  lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
};

if (window.innerWidth > 992) {
  window.addEventListener('DOMContentLoaded', () => {
    handleInitGalleryFirstBlock();
    handleInitGallerySecondBlock();

    window.addEventListener(
      'scroll',
      throttle(e => {
        handleChangeDirectionWhenScroll();
      }, 300)
    );
  });
}

if (window.innerWidth < 992) {
  const SLIDER_SPEED = 100000;

  const carouselFirst = new Swiper('.js-partners-carousel.first', {
    loop: true,
    freeMode: true,
    spaceBetween: 20,
    width: 2015,
    allowTouchMove: false,
    simulateTouch: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      stopOnLastSlide: false,
    },
    speed: SLIDER_SPEED,
  });

  const carouselSecond = new Swiper('.js-partners-carousel.second', {
    loop: true,
    freeMode: true,
    spaceBetween: 20,
    width: 1935,
    allowTouchMove: false,
    simulateTouch: false,
    autoplay: {
      disableOnInteraction: false,
      stopOnLastSlide: false,
    },
    speed: SLIDER_SPEED,
  });

  carouselSecond.slidePrev();
  setInterval(() => {
    carouselSecond.slidePrev();
  }, 2000);
}
