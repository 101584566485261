import refs from '../refs/refs';
const { backdropPolicy, policyLink, backdropPolicyCloseBtn } = refs;

const handleOpenModalPolicy = e => {
  e.preventDefault();
  backdropPolicy.classList.add('is-show');
  document.body.style.overflow = 'hidden';
};

const handleCloseModal = () => {
  backdropPolicy.classList.remove('is-show');
  document.body.style.overflow = 'auto';
};

const handleCloseOnBackdrop = e => {
  if (e.target === e.currentTarget) {
    handleCloseModal();
  }
};

policyLink.addEventListener('click', handleOpenModalPolicy);
backdropPolicyCloseBtn.addEventListener('click', handleCloseModal);
backdropPolicy.addEventListener('click', handleCloseOnBackdrop);
