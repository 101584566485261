import refs from '../refs/refs';
const { playVideoBtns, reviewVideos } = refs;

const handlePlayVideo = e => {
  const btn = e.currentTarget;
  const btnId = btn.dataset.id;
  const overflow = btn.nextElementSibling;

  reviewVideos.forEach(video => {
    const videoId = video.dataset.id;
    if (videoId === btnId) {
      video.play();
      video.setAttribute('controls', 'true');
      btn.classList.add('visually-hidden');
      overflow.classList.add('visually-hidden');
    }
  });
};

playVideoBtns.forEach(btn => {
  btn.addEventListener('click', handlePlayVideo);
});

reviewVideos.forEach(video => {
  video.addEventListener('playing', () => {
    const name = video.nextElementSibling;
    name.classList.add('visually-hidden');
  });
});
