import refs from '../refs/refs';
import { scrollToTarget } from '../common/scroll-to-target';
const { caseItems, caseShowBtn } = refs;

const items = [...caseItems].slice(2);

const handleInitHiddenCases = items => {
  items.forEach(item => {
    item.classList.add('visually-hidden');
    item.classList.add('is-hidden');
  });
};

const handleShowCases = (e, items) => {
  const btn = e.currentTarget;
  btn.classList.toggle('is-show');

  if (btn.classList.contains('is-show')) {
    btn.textContent = '(скрыть)';

    items.forEach(item => {
      item.classList.remove('visually-hidden');

      setTimeout(() => {
        item.classList.remove('is-hidden');
      }, 200);
    });

    scrollToTarget(caseItems[caseItems.length - 2]);
  } else {
    btn.textContent = '(показать еще)';

    items.forEach(item => {
      setTimeout(() => {
        item.classList.add('visually-hidden');
      }, 400);
      item.classList.add('is-hidden');
    });

    scrollToTarget(caseItems[1]);
  }
};

window.addEventListener('DOMContentLoaded', () => {
  handleInitHiddenCases(items);
});

caseShowBtn.addEventListener('click', e => {
  handleShowCases(e, items);
});
