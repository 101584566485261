import Swiper from 'swiper/swiper-bundle.min.mjs';
import refs from '../refs/refs';
const {
  tabBtns1,
  tabBtns2,
  tabBtns3,
  tabBtns4,
  tabSliderCase1,
  tabSliderCase2,
  tabSliderCase3,
  tabSliderCase4,
} = refs;

const tabSlider1 = new Swiper(tabSliderCase1, {
  spaceBetween: 30,
  initialSlide: 2,
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
});

const tabSlider2 = new Swiper(tabSliderCase2, {
  spaceBetween: 30,
  initialSlide: 2,
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
});

const tabSlider3 = new Swiper(tabSliderCase3, {
  spaceBetween: 30,
  initialSlide: 2,
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
});

const tabSlider4 = new Swiper(tabSliderCase4, {
  spaceBetween: 30,
  initialSlide: 2,
  autoHeight: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
});

const handleSwitchTab = (e, slider) => {
  const btn = e.currentTarget;
  const btnId = btn.dataset.id;

  slider.slideTo(btnId);
};

const handleSwitchTabOnSlide = (e, tabBtns) => {
  const slideIdx = e.activeIndex;

  tabBtns.forEach(btn => btn.classList.remove('is-active'));
  tabBtns[slideIdx].classList.add('is-active');
};

tabSlider1.on('slideChange', e => {
  handleSwitchTabOnSlide(e, tabBtns1);
});
tabSlider2.on('slideChange', e => {
  handleSwitchTabOnSlide(e, tabBtns2);
});
tabSlider3.on('slideChange', e => {
  handleSwitchTabOnSlide(e, tabBtns3);
});
tabSlider4.on('slideChange', e => {
  handleSwitchTabOnSlide(e, tabBtns4);
});

tabBtns1.forEach(btn => {
  btn.addEventListener('click', e => {
    handleSwitchTab(e, tabSlider1);
  });
});

tabBtns2.forEach(btn => {
  btn.addEventListener('click', e => {
    handleSwitchTab(e, tabSlider2);
  });
});

tabBtns3.forEach(btn => {
  btn.addEventListener('click', e => {
    handleSwitchTab(e, tabSlider3);
  });
});

tabBtns4.forEach(btn => {
  btn.addEventListener('click', e => {
    handleSwitchTab(e, tabSlider4);
  });
});
