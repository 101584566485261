import refs from '../refs/refs';

const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.intersectionRatio >= 1) {
        entry.target.classList.add('is-show');
      }
    });
  },
  { threshold: 1 }
);

refs.bgTexts.forEach(text => {
  observer.observe(text);
});
