import refs from '../refs/refs';
const { countNumbers } = refs;

const handleInitCountNumbers = numbers => {
  numbers.forEach(number => {
    const initNum = Number(number.textContent);
    let result;
    result = Math.round(initNum / 3);
    if (initNum > 50) {
      result = initNum - 50;
    }
    number.textContent = result;
  });
};

const observerCb = entries => {
  entries.forEach(entry => {
    const number = entry.target;
    const maxValue = Number(number.dataset.value);
    let currentValue = Number(number.textContent);
    let intervalId;

    if (entry.isIntersecting) {
      intervalId = setInterval(() => {
        if (maxValue <= currentValue) {
          clearInterval(intervalId);
          return;
        }

        currentValue += 1;
        number.textContent = currentValue;
      }, 50);
    }
  });
};

const observer = new IntersectionObserver(observerCb, { threshold: 0.4 });

countNumbers.forEach(number => {
  observer.observe(number);
});

window.addEventListener('DOMContentLoaded', () => {
  handleInitCountNumbers(countNumbers);
});
