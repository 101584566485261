import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const BREAKPOINTS = {
  mobileM: 600,
  tablet: 768,
};

gsap.registerPlugin(ScrollTrigger);

if (window.innerWidth < BREAKPOINTS.tablet) {
  const tl = gsap.timeline();
  tl.from('.reviews-list', { x: -1000 });

  ScrollTrigger.create({
    animation: tl,
    trigger: '.reviews-list',
    start: '-40px top',
    end: '+=2770',
    scrub: true,
    pin: true,
    anticipatePin: 1,
  });
} else {
  const tl = gsap.timeline();
  tl.from('.js-gsap-trigger', {
    opacity: 0,
    y: 0,
  });

  ScrollTrigger.create({
    animation: tl,
    trigger: '.js-gsap-trigger',
    start: 'top center',
    end: '930px center',
    pin: true,
    pinSpacing: false,
  });
}
