export const getInputName = selector =>
  document.querySelector(`${selector} .js-form__input[name="name"]`);

export const getInputPhone = selector =>
  document.querySelector(`${selector} .js-form__input[name="phone"]`);

export const getErrorInputName = selector =>
  document.querySelector(`${selector} .js-input-error-name`);

export const getErrorIconName = selector =>
  document.querySelector(`${selector} .js-error-icon-name`);

export const getErrorInputPhone = selector =>
  document.querySelector(`${selector} .js-input-error-phone`);

export const getErrorIconPhone = selector =>
  document.querySelector(`${selector} .js-error-icon-phone`);
