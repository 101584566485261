import refs from '../refs/refs';
import axios from 'axios';
import {
  getErrorInputName,
  getErrorIconName,
  getErrorInputPhone,
  getErrorIconPhone,
} from '../refs/get-refs';

const handleSubmit = async (e, selector, path) => {
  e.preventDefault();

  const { name, phone } = e.currentTarget.elements;

  if (name.value.trim() === '' && phone.value.trim() === '') {
    getErrorInputName(selector).classList.add('is-error');
    name.classList.add('is-error');
    getErrorIconName(selector).classList.add('is-error');

    getErrorInputPhone(selector).classList.add('is-error');
    phone.classList.add('is-error');
    getErrorIconPhone(selector).classList.add('is-error');
    return;
  }

  if (name.value.trim() === '') {
    getErrorInputName(selector).classList.add('is-error');
    name.classList.add('is-error');
    getErrorIconName(selector).classList.add('is-error');
    return;
  }
  if (phone.value.trim() === '' || phone.value.length < 16) {
    getErrorInputPhone(selector).classList.add('is-error');
    phone.classList.add('is-error');
    getErrorIconPhone(selector).classList.add('is-error');
    return;
  }

  const formData = new FormData();
  formData.append('name', name.value);
  formData.append('phone', phone.value);

  try {
    await axios.post('sendForm.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    window.location.href = path;
  } catch (error) {
    const errorSubmit = document.querySelector(`${selector} .js-submit-error`);
    errorSubmit.classList.add('is-error');
    name.classList.add('is-error');
    phone.classList.add('is-error');

    setTimeout(() => {
      errorSubmit.classList.remove('is-error');
      name.classList.remove('is-error');
      phone.classList.remove('is-error');
    }, 5000);
  }
};

refs.form.addEventListener('submit', e => {
  handleSubmit(e, '.js-main-form', '/success.html');
});
refs.formTest.addEventListener('submit', e => {
  handleSubmit(e, '.js-test-form', '/success-test.html');
});
