const refs = {
  bgTexts: document.querySelectorAll('.js-bg-text'),
  cbBtns: document.querySelectorAll('.js-btn-cb-form'),

  howItWorksListContainerDesktop: document.querySelector(
    '.js-how-it-works-list .container-desktop'
  ),
  howItWorksListWidthBoxMobile: document.querySelectorAll(
    '.js-how-it-works-list .width-box'
  ),

  heroCircleBtns: document.querySelectorAll('.js-hero-circle-btn'),
  heroDownBtns: document.querySelectorAll('.js-hero-down-btn'),
  heroMainTitle: document.querySelector('.js-main-title'),
  heroMainTitleGhost: document.querySelector('.js-main-title-ghost'),

  sectionHowItWorks: document.getElementById('how-it-works'),
  sectionBenefits: document.getElementById('benefits'),

  countNumbers: document.querySelectorAll('.js-count-number'),

  sectionPartners: document.querySelector('.js-section-partnets'),
  partnersGalleryBlockFirst: document.querySelector(
    '.js-partners-block-item.first'
  ),
  partnersGalleryBlockSecond: document.querySelector(
    '.js-partners-block-item.second'
  ),

  caseItems: document.querySelectorAll('.js-cases-list .item'),

  tabBtns1: document.querySelectorAll('.js-tab-btn-list-case1 .js-tab-btn'),
  tabSliderCase1: document.querySelector(
    '.js-case-desc-tab[data-case="case1"]'
  ),
  tabBtns2: document.querySelectorAll('.js-tab-btn-list-case2 .js-tab-btn'),
  tabSliderCase2: document.querySelector(
    '.js-case-desc-tab[data-case="case2"]'
  ),
  tabBtns3: document.querySelectorAll('.js-tab-btn-list-case3 .js-tab-btn'),
  tabSliderCase3: document.querySelector(
    '.js-case-desc-tab[data-case="case3"]'
  ),
  tabBtns4: document.querySelectorAll('.js-tab-btn-list-case4 .js-tab-btn'),
  tabSliderCase4: document.querySelector(
    '.js-case-desc-tab[data-case="case4"]'
  ),
  caseShowBtn: document.querySelector('.js-cases-show-btn'),

  playVideoBtns: document.querySelectorAll('.js-play-video-btn'),
  reviewVideos: document.querySelectorAll('.js-review-video'),

  backdropForm: document.querySelector('.js-backdrop.simple-form'),
  backdropFormCloseBtn: document.querySelector(
    '.js-backdrop.simple-form .js-modal__close-btn'
  ),
  backdropFormTest: document.querySelector('.js-backdrop.form-test'),
  backdropFormTestCloseBtn: document.querySelector(
    '.js-backdrop.form-test .js-modal__close-btn'
  ),

  form: document.querySelector('.js-main-form'),
  formTest: document.querySelector('.js-test-form'),

  currentYear: document.querySelector('.js-current-year'),
  policyLink: document.querySelector('.js-policy-link'),
  backdropPolicy: document.querySelector('.js-backdrop-policy'),
  backdropPolicyCloseBtn: document.querySelector(
    '.js-backdrop-policy .js-modal__close-btn'
  ),
};

export default refs;
