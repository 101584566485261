import refs from '../refs/refs';
const {
  backdropForm,
  backdropFormCloseBtn,
  backdropFormTest,
  backdropFormTestCloseBtn,
  cbBtns,
} = refs;

const handleOpenModal = e => {
  if (e.currentTarget.classList.contains('simple-form')) {
    backdropForm.classList.add('is-show');
    document.body.style.overflow = 'hidden';
  }

  if (e.currentTarget.classList.contains('test-form')) {
    backdropFormTest.classList.add('is-show');
    document.body.style.overflow = 'hidden';
  }
};

const handleCloseModal = backdrop => {
  backdrop.classList.remove('is-show');
  document.body.style.overflow = 'auto';
};

const handleCloseOnBackdrop = e => {
  if (e.target === e.currentTarget) {
    e.currentTarget.remove('is-show');
    document.body.style.overflow = 'auto';
  }
};

window.addEventListener('DOMContentLoaded', () => {
  cbBtns.forEach(btn => {
    btn.addEventListener('click', handleOpenModal);
  });
  backdropForm.addEventListener('click', handleCloseOnBackdrop);
  backdropFormTest.addEventListener('click', handleCloseOnBackdrop);
  backdropFormCloseBtn.addEventListener('click', () => {
    handleCloseModal(backdropForm);
  });
  backdropFormTestCloseBtn.addEventListener('click', () => {
    handleCloseModal(backdropFormTest);
  });
});
